@import url("colors.css");

body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

.wrapper {
  max-width: 800px;
  margin: 20px auto;
}

@media screen and (max-width: 600px) {
  .wrapper {
    max-width: 400px;
  }
  .msg_title {
    font-size: 20px;
  }
  .msg_body {
    font-size: 14px;
  }
}
