.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin: 0 15px;
}

.navbar li.active > a {
  text-decoration: underline;
}
