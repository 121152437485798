.msg_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.error {
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 999;
  background-color: pink;
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  width: calc(100% - 22px);
  box-sizing: border-box;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.inline-container {
  width: 100%;
  display: flex;
}

.input-file {
  flex: 1;
  margin-right: 5px;
}

.button-reset {
  background-color: var(--link-color);
  flex-shrink: 0;
}

.button-reset:hover {
  background-color: var(--hover-color);
}

button {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 0px;
  color: #fff;
}

.button-submit {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-valid {
  background-color: var(--link-color);
  cursor: pointer;
}

.button-invalid {
  background-color: gray;
  cursor: not-allowed;
}

.button-valid:hover {
  background-color: var(--hover-color);
}

.spinner-sm {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
