.contact-us-button {
  width: 100%;
  background-color: var(--link-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.contact-us-button:hover {
  background-color: var(--hover-color);
}
