.container {
  margin: 20px 0px;
  padding: 30px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.msg_title__teaser {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.msg_header {
  color: grey;
  margin-bottom: 20px;
}

.small-text {
  font-size: 14px;
}

.msg_teaser {
  margin: 15px 0px;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
}

.teaser__footer-left {
  display: flex;
  justify-content: flex-start;
}

.teaser__footer-right {
  display: flex;
  justify-content: flex-end;
}

.msg_body {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
}

.link {
  text-decoration: underline;
  color: var(--link-color);
}

.link:hover {
  color: var(--hover-color);
}
